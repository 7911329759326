<template>
    <modal ref="modalCambiarDescuento" titulo="Cambiar el beneficio de descuento de los leecheros del cedis" no-aceptar adicional="Guardar" tamano="modal-lg" @adicional="trabajar">
        <ValidationObserver ref="validacion">
            <cargando v-if="cargando" />
            <section class="mx-4">
                <div class="row mx-0 mt-3">
                    <div class="col-12">
                        <p class="text-general f-600">
                            Descuento de lista
                        </p>
                    </div>
                </div>
                <div class="row mx-0 mt-4">
                    <div class="col-12">
                        <p class="text-general">
                            Los nuevos {{ $config.vendedor }} del cedis pueden acogerse al beneficio de descuento de lista.
                        </p>
                    </div>
                </div>
                <div class="row mx-0 mt-4">
                    <div class="col">
                        <div class="row mx-2 mt-3">
                            <div class="col-auto d-middle text-general">
                                <input v-model="model.descuentoLeecheros" :value="1" type="radio" class="option-input radio black" />
                                <span class="ml-14 f-15">
                                    De manera automatica
                                </span>
                            </div>
                        </div>
                        <div class="row mx-2 mt-4">
                            <div class="col-auto d-middle text-general">
                                <input v-model="model.descuentoLeecheros" :value="2" type="radio" class="option-input radio black" />
                                <span class="ml-14 f-15">
                                    De manera manual
                                </span>
                            </div>
                        </div>
                        
                        <div v-if="model.descuentoLeecheros === 2" class="row mx-5 mt-2">
                            <div class="col-auto">
                                <p class="f-12 text-general ml-2">Clasificación</p>
                                <ValidationProvider v-slot="{errors}" rules="required" name="Clasificación">
                                    <el-select v-model="model.categoriaLista" size="small" placeholder="Seleccionar">
                                        <el-option
                                        v-for="item in listaClasificacion"
                                        :key="item.id"
                                        :label="item.nombre"
                                        :value="item.id"
                                        />
                                    </el-select>
                                    <div>
                                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="row mx-2 mt-4">
                            <div class="col-auto d-middle text-general">
                                <input v-model="model.descuentoLeecheros" :value="0" type="radio" class="option-input radio black" />
                                <span class="ml-14 f-15">
                                    No acogerlos al beneficio.
                                </span>
                            </div>
                        </div>
                        <div class="row mx-2 mt-4">
                            <div class="col-auto d-middle text-general">
                                <el-checkbox v-model="model.descuentoClientes" :true-label="1" :false-label="0" class="check-green" />
                                <span class="ml-3 f-15">Los clientes de los {{ $config.vendedor }} nuevos del cedis pueden acogerse al beneficio de descuento de lista</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ValidationObserver>
    </modal>
</template>
<script>
import Service from "~/services/alianzas_leecheros";

export default {
    data(){
        return{
            cargando: false,
            listaClasificacion: [],
            model: {
                idCedis: this.$route.params.id_cedis,
                descuentoLeecheros: 0,
                descuentoClientes: 0,
                categoriaLista: null,
            },
        }
    },

    methods: {
        async toggle(){
            this.$refs.validacion.reset();
            await this.getData();
            this.$refs.modalCambiarDescuento.toggle();
        },

        async getData(){
            try {
                const {data} = await Service.getParametrosCedis(this.model.idCedis);
                this.listaClasificacion = data.clasificaciones;
                this.model.descuentoLeecheros = data.data.descuento_lista;
                this.model.descuentoClientes = data.data.descuento_lista_clientes;
                this.model.categoriaLista = data.data.id_categoria_lista;
            } catch(e){
                this.error_catch(e);
            }
        },

        async trabajar(){
            try {
                const valid = await this.$refs.validacion.validate();
                if(!valid)return;

                this.cargando = true;
                if(parseInt(this.model.descuentoLeecheros) === 0){
                    this.model.categoriaLista = null;
                }
                const {data} = await Service.postDescuentoTienda(this.model);
                if(data.exito){
                    this.notificacion('Mensaje', data.mensaje, data.tipo);
                    this.$emit('update');
                    this.$refs.modalCambiarDescuento.toggle();
                }
            } catch(e){
                this.error_catch(e);
            } finally{
                this.cargando = false;
            }
        },
    },
}
</script>
<style lang="scss" scoped>

</style>
